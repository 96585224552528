<div class="container py-5 py-lg-6">
    <div class="row">
        <div class="col-lg-4 pe-lg-5">
            @for (advantage of body.items.slice(0, 2); track advantage) {
            <div class="mt-5 mt-lg-6" appAos>
                <div class="lotti-wrapper mb-4">
                    @if (isBrowser){
                    <ng-lottie [options]="{ path: advantage.lottie_file?.url }"></ng-lottie>
                    }
                </div>
                <div [innerHTML]="advantage.title | renderHtml"></div>
                <div [innerHTML]="advantage.description | renderHtml"></div>
            </div>
            }
        </div>
        <div class="col-lg-4 d-flex align-items-center" appAos>
            <img [src]="body.primary.image.url" [alt]="body.primary.image.alt" class="w-100 image-mask" />
        </div>
        <div class="col-lg-4 col-lg-4 ps-lg-5">
            @for (advantage of body.items.slice(2, 4); track advantage) {
            <div class="mt-5 mt-lg-6" appAos>
                <div class="lotti-wrapper mb-4">
                    @if (isBrowser){
                    <ng-lottie [options]="{ path: advantage.lottie_file?.url }"></ng-lottie>
                    }
                </div>
                <div [innerHTML]="advantage.title | renderHtml"></div>
                <div [innerHTML]="advantage.description | renderHtml"></div>
            </div>
            }
        </div>
    </div>
</div>
