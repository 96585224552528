import { isPlatformBrowser } from '@angular/common';
import { Component, inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ImageField, LinkField, RichTextField, Slice, TitleField } from '@prismicio/client';
import { LottieComponent } from 'ngx-lottie';
import { AosDirective } from '../../aos/aos.directive';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-advantages-slice',
    templateUrl: './advantages-slice.component.html',
    styleUrls: ['./advantages-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, LottieComponent, RenderHtmlPipe]
})
export class AdvantagesSliceComponent implements OnInit {
    @Input() body!: AdvantagesSlice;
    private platformId = inject<Object>(PLATFORM_ID);
    isBrowser = isPlatformBrowser(this.platformId);

    constructor() {}

    ngOnInit(): void {}
}

export type AdvantagesSlice = Slice<
    'vorteile',
    {
        image: ImageField;
    },
    Advantage
>;

export type Advantage = {
    lottie_file: LinkField;
    title: TitleField;
    description: RichTextField;
};
